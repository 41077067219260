<template>
<div >
        <Modal v-model="showprint" title="查看供方目录" width="1350px">
            <supplierPrint v-if="this.modelnoSelected !=null" v-bind:suppliers="this.tbldata" v-bind:modelno="this.modelnoSelected" />
        </Modal>
        <div style="text-align: left;">
            <h2>查询供方目录</h2>
        </div>
        <Form ref="queryForm" :model="queryFormInline" label-position="right" :label-width="100" style="margin-bottom:20px;border:1px solid lightgray;background:lightblue;">
            <table width="100%">
                <tr>
                    <td align="right" width="23%">
                        <FormItem prop="parentid"  label="适用车型:" style="margin-bottom:5px;margin-top:10px">
                            <i-select v-model="queryFormInline.parentid">
                                <i-option value="" key=0>请选择车型</i-option>
                                <i-option v-for="(item,index) in modellist" :value="item.id" :key="item.id">{{item.modelno}}</i-option>
                            </i-select>
                        </FormItem>
                    </td>
                    <td align="right" width="23%">
                        <FormItem  label="供应商名称:" style="margin-bottom:5px;margin-top:10px">
                            <i-input type="text" v-model="queryFormInline.supplier">
                            </i-input>
                        </FormItem>
                    </td>
                    <td align="left">
                        <div style="display: inline-flex;justify-items: center;padding-left: 20px;">
                            <Button type="primary" @click="handleQuery()" >查询</Button>    
                            <Button  @click="handleReset()" style="margin-left:10px">重置</Button>
                        </div>
                    </td>
                </tr>
            </table>
        </Form>
        
        <div style="height:40px;width:100%;">
            <Button v-if="usercategory != 'third'" type="warning"  style="margin-top:3px;float:left" @click="addsupplier">添加供应商</Button>
            <Button type="primary" v-if="tbldata.length>0"  style="margin-top:3px;margin-right: 10px;float:right" @click="showprint=true">打印供方目录</Button>
            <Button type="success" ghost v-if="tbldata.length>0"  style="margin-top:3px;margin-right: 30px;float:right" @click="redirectcght">查看采购合同</Button>
        </div>
        <!-- 账号列表-->
        <div style="padding-top:10px">
            <Table stripe :columns="tblcolumns" :data="tbldata" size="small" border>
            <template slot-scope="{ row }" slot="supplier">
                <strong>{{ row.supplier }}</strong>
            </template>
            <template slot-scope="{ row }" slot="productmodelno">
                <p>{{ row.productmodelno==null? row.product : row.productmodelno }}</p>
            </template>
            <template slot-scope="{ row,index }" slot="cccno">
                <div>
                    <strong>{{ row.cccno }}</strong>
                    <div style="margin:0 auto">
                        <a v-if="row.cccimg !=null" @click="viewpic(index)" class="aview">查看</a>
                        <a v-if="row.cccimg !=null" @click="removepic(index)" class="aview">删除报告</a>
                    </div>
                    
                </div>
            </template>
            <template slot-scope="{ row, index }" slot="action" >
                <div class="opbar">
                    <Button v-if="row.autoimport !=true" type="error" size="small" @click="remove(index)">删除</Button>
                    <Button type="warning" size="small" @click="upload(index)" >上传检验报告</Button>
                    <Button v-if="row.autoimport !=true" type="info" size="small" ghost @click="modify(index)">修改</Button>
                    <Button type="info" size="small" ghost @click="viewscore(index)">评价表</Button>
                    <Button type="error" size="small" ghost @click="printscore(index)">打印评价表</Button>
                </div>
            </template>
            </Table>
        </div>
        <supplierEdit v-bind:show="showeditsupplier" v-bind:supplierdata="editsupplierdata" @Closesupplier="onEditClose" @SubmmitOver="onSubmmitOver"/>
        <Modal v-model="showdeleteconfirm" title="删除供应商" @on-ok="confirmDelete">确定要删除吗？</Modal>
        <Modal v-if="opindex !=null" v-model="showuploadwin" title="上传图片" :closable="false" @on-ok="showuploadwin=false">
            <div style="margin: 0 auto;width: 90%;text-align: center;align-items: center;">
                <Upload
                    type="drag"
                    action="/api/files/checkdept/supplier/files/upload"
                    :data= "{
                        id : tbldata[opindex].id,
                        companyid: tbldata[opindex].companyid
                    }"
                    :format="['pdf','doc','docx','ppt','xls','xlsx','jpg','jpeg','bmp']"
                    :on-success="handleSuccess"
                    >
                    <div style="padding: 20px 0">
                        <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                        <p>点击或拖拽文件进行上传</p>
                    </div>
                </Upload>
            </div>

        </Modal>
    </div>
</template>

<script>
import SupplierEdit from './SupplierEdit.vue'
import SupplierPrint from '@/views/business/workshop/checkdept/supplier/SupplierPrint'
import {getPrinters,public_print,public_print_with_dir,public_preview_with_dir,public_printInnerHtml_with_dir} from '@/zsjs/zskjprinter.js'
export default {
    name:'supplier',
    data () {
            return {
                usercategory: this._self.$root.$store.state.LogonInfo.userCategory,
                showeditsupplier:false,
                editsupplierdata:null,
                showdeleteconfirm:false,
                showuploadwin:false,
                showprint:false,
                deleteIndex:null,
                opindex:null,
                modelnoSelected:null,
                modellist:this._self.$root.$store.state.ModelListInfo,
                queryFormInline:{
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    parentid:null,
                    supplier:null,
                },
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '供应商名称',
                        slot: 'supplier',
                        width:260,
                        resizeable:true,
                        tooltip :true
                    },
                    {
                        title: '产品',
                        key: 'product',
                        tooltip :true
                    },   
                    {
                        title: '规格型号',
                        slot: 'productmodelno',
                        tooltip :true
                    },         
                    {
                        title: '备注',
                        slot: 'cccno',
                        tooltip :true
                    },        
                    {
                        title: '操作',
                        slot: 'action',
                        align: 'center',
                        width: 300,
                        fixed:'right'
                    }
                    
                ],
                tbldata: [],
                scoredoc:null
            }
        },
        mounted (){
            //this.handleQuery()
            console.log(this.queryFormInline)
            if(this.$route.query.modelid !=null){
                setTimeout(()=>{
                    this.queryFormInline.parentid = parseInt(this.$route.query.modelid)
                    this.handleQuery()
                },200)
            }
        },
        methods: {
            handleQuery() {
                let postdata = JSON.parse(JSON.stringify(this.queryFormInline))
                for(var p in postdata){
                    if(postdata[p] == null || postdata[p] ==''){
                        delete postdata[p]
                    }
                }

                if(this.queryFormInline.parentid ==null){
                    this.$Message.error({content:'请选择车型！',duration:3})
                    return
                }
                this.modelnoSelected = this.getmodelnobyid(this.queryFormInline.parentid)
                this.$axios({
                    method:'post',
                    url:"/api/checkdept/purchase/supplier/list",
                    data:postdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        var rspdata = res.data.data
                        this.tbldata =rspdata
                        //this.$Message.success({content:res.data.msg, duration:3})
                        console.log(this)
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            addsupplier(){
                this.editsupplierdata = null
                this.showeditsupplier = true                
            },
            modify (index) {
                this.editsupplierdata = this.tbldata[index]
                this.showeditsupplier = true
            },
            confirmDelete(){
                this.$axios({
                    method:'post',
                    url:"/api/checkdept/purchase/supplier/delete",
                    data:{id:this.tbldata[this.deleteIndex].id},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            viewpic (index){
                this.opindex = index
                let openurl = window.location.protocol+"//"+window.location.host+this.tbldata[index].cccimg
                window.open(openurl, '_blank');
            },
            removepic (index){
                this.opindex = index
                this.$axios({
                    method:'post',
                    url:"/api/checkdept/purchase/supplier/deleteimg",
                    data:{id:this.tbldata[this.opindex].id},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.tbldata[this.opindex].cccimg = null
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));    
            },
            upload (index){
                this.opindex = index
                this.showuploadwin = true
                console.log(this)
            },
            viewscore (index){
                let companyCode = this.$route.params.companyCode
                console.log(this.$route)
                console.log(companyCode)
                
                let acturl = window.btoa('/api/checkdept/doc/supplierscore/get')
                let actdata =window.btoa(JSON.stringify({
                    companyid: this._self.$root.$store.state.LogonInfo.companyid,
                    supplierid:this.tbldata[index].id
                })) 

                let routeUrl = this.$router.resolve({
                    name:'docview',
                    query:{acturl:acturl, actdata:actdata}
                });
                window.open(routeUrl.href, '_blank');
            },
            redirectcght(){
                this.$router.push({path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/shd'})
            },
            printscore(index){
                let acturl = '/api/checkdept/doc/supplierscore/get'
                let actdata ={
                    companyid: this._self.$root.$store.state.LogonInfo.companyid,
                    supplierid:this.tbldata[index].id
                }
                this.$axios({
                    method:'post',
                    url:acturl,
                    data:actdata,
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    console.log(res.data)
                    if(res.data.resultCode ==0){
                        this.scoredoc = res.data.data
                        this.printcontent(this.scoredoc.doccontent)
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));    

            },
            printcontent(data){
                public_printInnerHtml_with_dir(data,1,this.scoredoc.direction);
            },
            onEditClose(){
                this.showeditsupplier =false
            },
            onSubmmitOver(){
                this.showeditsupplier =false
                this.handleQuery()
            },
            handleSuccess(response, file, fileList){
                this.tbldata[this.opindex].cccimg = response.data.cccimg
                this.showuploadwin = false
                this.$Message.success({content:"文件上传成功", duration:3})
                console.log(this.tbldata[this.opindex].cccimg)
            },
            getmodelnobyid(id){
                for(let i=0;i<this.modellist.length;i++){
                    if(this.modellist[i].id == id){
                        return this.modellist[i].modelno
                    }
                }

                return null
            }
        },
        watch: {
            $route: {
                handler: function(val, oldVal){
                    console.log(val)
                    this.currentMenuName = this.getMenuName(val.path)
                },
                // 深度观察监听
                deep: true
            }
        },
        components:{
            supplierEdit:SupplierEdit,
            supplierPrint:SupplierPrint
        }
}
</script>
<style scoped>
    .aview {
        margin: 5px;
    }

    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 4px;
    }

    .ivu-upload {
        display:table-cell;
        align-items: center;
        vertical-align: middle;
        text-align: center;
    }
    .ivu-upload .ivu-upload-select {
        height: 100%;
        align-items: center;
        vertical-align: middle;
        text-align: center;
    }

    .ivu-upload .ivu-upload-select button{
        margin:0px 4px;
    }
</style>