<template>
    <Modal :value="show" width="40%" @on-cancel="oncancel" :mask-closable=false @on-visible-change="onshow">
        <p slot="header" style="color:#f60;text-align:left">
            <span>{{title}}</span>
        </p>
        <Form ref="supplierForm" :model="formInline" :label-width="180" label-position="right" :rules="ruleInline">
            <Row  >
                <i-col span="24">
                    <FormItem prop="supplier" label="适用产品:">
                        <i-select v-model="formInline.parentid" @on-select="handleAddSelectModel">
                            <i-option value=null key=0>请选择车型</i-option>
                            <i-option v-for="(item,index) in modellist" :value="item.id" :key="item.id">{{item.modelno}}</i-option>
                        </i-select>
                    </FormItem>
                </i-col>
            </Row>
            <Row  >
              <i-col span="24">
                <FormItem prop="supplier" label="供应商名称:">
                    <i-input type="text" v-model="formInline.supplier" :maxlength=30 show-word-limit></i-input>
                </FormItem>
              </i-col>
            </Row>
            <Row>  
              <i-col span="24">  
                <FormItem prop="product" label="产品">
                    <i-input type="text" v-model="formInline.product" :maxlength=30 show-word-limit></i-input>
                </FormItem>                  
              </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                    <FormItem prop="productmodelno" label="产品规格型号">
                        <i-input type="text" v-model="formInline.productmodelno" :maxlength=30 show-word-limit></i-input>
                    </FormItem>                  
                </i-col>
            </Row>
            <Row>  
                <i-col span="24">  
                    <FormItem prop="cccrequire" label="认证要求">
                        <i-input type="text" v-model="formInline.cccrequire" :maxlength=30 show-word-limit></i-input>
                    </FormItem>                  
                </i-col>
            </Row>
            <Row>  
            <i-col span="24">  
                <FormItem prop="cccno" label="认证编号">
                    <i-input type="text" v-model="formInline.cccno" :maxlength=30 show-word-limit></i-input>
                </FormItem>                  
            </i-col>
            </Row>                                                    
        </Form>
        <div slot="footer">
            <Button type="error" size="large" long  @click="handleSubmit('supplierForm')">提交</Button>
        </div>
    </Modal>
</template>
<script>
    export default {
        name:'supplierEdit',
        props:['supplierdata','show'],
        data () {
            return {
                title:'',
                modellist:this._self.$root.$store.state.ModelListInfo,
                formInline: {
                    companyid:this._self.$root.$store.state.LogonInfo.companyid,
                    parentid : this.supplierdata==null?null:this.supplierdata.parentid ,
                    applymodelno : this.supplierdata==null?null:this.supplierdata.applymodelno,
                    supplier: this.supplierdata==null?'':this.supplierdata.supplier,
                    product: this.supplierdata==null?'':this.supplierdata.product,
                    productmodelno: this.supplierdata==null?'':this.supplierdata.productmodelno,
                    cccrequire :this.supplierdata==null?'':this.supplierdata.cccrequire,
                    cccno :this.supplierdata==null?'':this.supplierdata.cccno,
                    autoimport : this.supplierdata==null?false:this.supplierdata.autoimport,
                },
                ruleInline: {
                    supplier: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    product: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                    parentid: [
                        { required: true, message: '不能为空', trigger: 'blur'}
                    ],
                }
            }
        },
        mounted(){
            console.log(this.supplierdata)
        },
        methods: {
            handleSubmit (name) {
                this.$refs[name].validate((valid)=>{
                    if(valid){
                        var url
                        var postdata
                        if(this.supplierdata ==null){
                            url = "/api/checkdept/purchase/supplier/addnew"
                            postdata = this.formInline
                        }
                        else{
                            url = "/api/checkdept/purchase/supplier/update"
                            postdata = this.extend({},this.formInline,{id:this.supplierdata.id})
                        }
                        this.$axios({
                            method:'post',
                            url:url,
                            data:postdata,
                            headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                            transformRequest: function(obj) {
                                var str = [];
                                for(var p in obj){
                                    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                                }
                                return str.join("&");
                            }
                        }).then(function(res){
                            if(res.data.resultCode ==0){
                                this.$Message.success(res.data.msg)
                                this.$emit('SubmmitOver','')
                               
                            }
                            else{
                                this.$Message.error(res.data.msg)
                            }
                            
                        }.bind(this))
                    }
                    else{
                        this.$Message.error('请输入有效数据')
                    }
                })
            },
            onshow(v){
                if(v){
                    this.formInline.supplier= this.supplierdata==null?'':this.supplierdata.supplier
                    this.formInline.product= this.supplierdata==null?'':this.supplierdata.product
                    this.formInline.parentid = this.supplierdata==null?null:this.supplierdata.parentid 
                    this.formInline.applymodelno = this.supplierdata==null?null:this.supplierdata.applymodelno
                    this.formInline.supplier= this.supplierdata==null?'':this.supplierdata.supplier
                    this.formInline.product= this.supplierdata==null?'':this.supplierdata.product
                    this.formInline.productmodelno= this.supplierdata==null?'':this.supplierdata.productmodelno
                    this.formInline.cccrequire =this.supplierdata==null?'':this.supplierdata.cccrequire
                    this.formInline.cccno= this.supplierdata==null?'':this.supplierdata.cccno

                    if(this.supplierdata ==null){
                        this.title ='新增供应商'
                    }
                    else{
                        this.title ='修改供应商'
                    }

                    this.formInline.autoimport = false
                }
            },
            oncancel(){
                this.$emit('Closesupplier','')
            },
            handleAddSelectModel(e){
                console.log(e.label +':'+e.value)

                for(let i=0;i<this.modellist.length;i++){
                    if(this.modellist[i].id == e.value){
                        this.formInline.applymodelno = this.modellist[i].modelno
                        break
                    }
                }
            }
        }
    }
</script>